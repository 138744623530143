html {
  scroll-behavior: smooth;
  font-family: Helvetica, Open Sans, Arial sans-serif;
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

*, :before, :after {
  box-sizing: border-box;
}

h1 {
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3rem;
}

@media (max-width: 1023px) {
  h1 {
    text-align: center;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 6rem;
  }
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 640px) {
  .logo {
    width: 150px;
  }
}

.container {
  max-width: 1300px;
  margin-inline: auto;
  padding: 0 15px;
}

.container-small {
  max-width: 1150px;
  margin-inline: auto;
  padding: 0 15px;
}

.black-bg {
  color: #fff;
  background: #000;
}

.white-bg {
  color: #000;
  background: #fff;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header__title {
  margin-bottom: 40px;
  font-size: 3rem;
  line-height: 125%;
}

@media (min-width: 1024px) {
  .header__title {
    font-size: 7rem;
  }
}

p {
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 150%;
}

.intro {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 1.8rem;
}

@media (min-width: 1024px) {
  .intro {
    font-size: 2.4rem;
  }
}

.nav {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 15px 0;
}

.nav-list {
  text-transform: uppercase;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  font-weight: 700;
  list-style-type: none;
  display: flex;
}

@media (max-width: 940px) {
  .nav-list {
    flex-direction: column;
    align-items: center;
  }
}

.nav-list a {
  text-decoration: none;
}

.nav-list a:hover {
  text-decoration: underline;
}

.intro h1 {
  text-align: center;
}

@media (min-width: 11024px) {
  .intro h1 {
    font-size: 8rem;
  }
}

@media (min-width: 1300px) {
  .intro h1 {
    font-size: 9rem;
  }
}

.postulate {
  padding: 40px 15px;
}

.postulate h1 {
  text-align: center;
}

.postulate ol {
  margin: 0;
  padding: 0 0 0 15px;
  font-size: 1.8rem;
  line-height: 150%;
}

.postulate ol li {
  margin-bottom: 5px;
}

@media (min-width: 1024px) {
  .postulate ol {
    padding: 0 0 0 25px;
    font-size: 2.2rem;
  }

  .postulate {
    border-bottom: 1px solid #000;
  }

  .postulate__inner {
    gap: 30px;
    display: flex;
  }

  .postulate__left {
    width: 40%;
  }

  .postulate__left h1 {
    text-align: left;
    margin-top: 0;
    font-size: 4.3rem;
    position: sticky;
    top: 20px;
  }

  .postulate__right {
    flex: 1 1 0;
  }
}

@media (min-width: 1220px) {
  .postulate__left h1 {
    font-size: 5rem;
  }
}

@media (min-width: 1360px) {
  .postulate__left h1 {
    font-size: 5.8rem;
  }
}

.about {
  padding: 20px 0;
  overflow: clip;
}

@media (max-width: 1023px) {
  .about__slogan {
    text-align: center;
    font-size: 5rem;
    font-weight: 700;
    line-height: 120%;
  }
}

@media (max-width: 480px) {
  .about__slogan {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  .about {
    padding: 0;
  }

  .about__inner {
    display: flex;
  }

  .about__left {
    color: #000;
    background: #fff;
    width: 50%;
    padding-top: 40px;
    position: relative;
  }

  .about__left:before {
    content: "";
    background: #fff;
    width: 50vw;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 1px);
  }

  .about__left h1 {
    position: sticky;
    top: 20px;
  }

  .about__right {
    flex: 1 1 0;
    padding: 40px 0 0 50px;
  }

  .about__right h2 {
    font-size: 3rem;
  }

  .about__right p {
    font-size: 2.4rem;
  }

  .about__slogan {
    align-items: center;
    min-height: 80vh;
    font-size: 10rem;
    font-weight: 700;
    line-height: 120%;
    display: flex;
  }
}

.poster {
  padding: 10px 0;
  overflow: clip;
}

.poster h1 {
  font-size: 6rem;
}

@media (max-width: 1023px) {
  .poster .left-inner {
    text-align: center;
    padding-bottom: 40px;
  }

  .poster .left-inner .btn {
    display: none;
  }

  .poster__right {
    text-align: center;
  }

  .poster__right .btn {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .poster {
    padding: 0;
  }

  .poster__inner {
    display: flex;
  }

  .poster .left-inner {
    text-align: right;
    position: sticky;
    top: 20px;
  }

  .poster__left {
    color: #fff;
    background: #000;
    width: 50%;
    padding-right: 50px;
    position: relative;
  }

  .poster__left:after {
    content: "";
    background: #000;
    width: 50vw;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 1px);
  }

  .poster__left h1 {
    font-size: 8rem;
  }

  .poster__right {
    flex: 1 1 0;
    position: relative;
  }

  .poster__right .btn {
    display: none;
  }
}

.footer {
  text-align: center;
  padding: 20vh 0 1px;
  font-size: 6rem;
  font-weight: 700;
}

.footer .nav {
  margin-top: 20vh;
  margin-bottom: 50px;
}

@media (min-width: 1024px) {
  .footer {
    font-size: 12rem;
  }
}

.btn {
  color: #000;
  background: #fff;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
  transition: opacity .2s;
  display: inline-block;
}

.btn:hover {
  opacity: .9;
}

@media (max-width: 1023px) {
  .btn {
    color: #fff;
    background: #000;
  }
}

.slider {
  aspect-ratio: 1 / 1.1;
  display: block;
  position: relative;
  overflow: hidden;
}

.slider .slide {
  aspect-ratio: 1 / 1.1;
  transition: opacity 1s;
  overflow: hidden;
}

.slider .slide:not(.current) {
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.slider .slide.current {
  z-index: 7;
  position: relative;
}

/*# sourceMappingURL=index.28316c2b.css.map */
