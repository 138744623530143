html {
  font-size: 62.5%;
  font-family: Helvetica, "Open Sans", Arial sans-serif;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    text-align: center;
  }

  @media (min-width: 1024px) {
    font-size: 6rem;
  }
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.logo {
  @media (max-width: 640px) {
    width: 150px;
  }
}

.container {
  max-width: 1300px;
  margin-inline: auto;
  padding: 0 15px;
}
.container-small {
  max-width: 1150px;
  margin-inline: auto;
  padding: 0 15px;
}

.black-bg {
  background: #000;
  color: #fff;
}

.white-bg {
  background: #fff;
  color: #000;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header__title {
  font-size: 3rem;
  line-height: 125%;
  margin-bottom: 40px;
}

@media (min-width: 1024px) {
  .header__title {
    font-size: 7rem;
  }
}

p {
  line-height: 150%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.intro {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 1.8rem;
  @media (min-width: 1024px) {
    font-size: 2.4rem;
  }
}

.nav {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;

  padding: 15px 0;
}

.nav-list {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  list-style-type: none;
  gap: 20px;
  text-transform: uppercase;
  font-size: 1.8rem;
  flex-wrap: wrap;
  font-weight: 700;

  @media (max-width: 940px) {
    flex-direction: column;
    align-items: center;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.intro {
  h1 {
    text-align: center;

    @media (min-width: 11024px) {
      font-size: 8rem;
    }

    @media (min-width: 1300px) {
      font-size: 9rem;
    }
  }
}

.postulate {
  padding: 40px 15px;

  h1 {
    text-align: center;
  }

  ol {
    line-height: 150%;
    font-size: 1.8rem;
    margin: 0;
    padding: 0 0 0 15px;

    li {
      margin-bottom: 5px;
    }
    @media (min-width: 1024px) {
      font-size: 2.2rem;
      padding: 0 0 0 25px;
    }
  }
  @media (min-width: 1024px) {
    border-bottom: 1px solid #000;
    &__inner {
      display: flex;
      gap: 30px;
    }

    &__left {
      width: 40%;

      h1 {
        font-size: 4.3rem;
        text-align: left;
        margin-top: 0;
        position: sticky;
        top: 20px;
      }
    }

    &__right {
      flex: 1 1 0;
    }
  }
  @media (min-width: 1220px) {
    &__left {
      h1 {
        font-size: 5rem;
      }
    }
  }

  @media (min-width: 1360px) {
    &__left {
      h1 {
        font-size: 5.8rem;
      }
    }
  }
}

.about {
  padding: 20px 0;
  overflow: clip;

  @media (max-width: 1023px) {
    &__slogan {
      font-size: 5rem;
      line-height: 120%;
      font-weight: 700;
      text-align: center;
    }
  }

  @media (max-width: 480px) {
    &__slogan {
      font-size: 3rem;
    }
  }
  @media (min-width: 1024px) {
    padding: 0;
    &__inner {
      display: flex;
    }

    &__left {
      width: 50%;
      background: #fff;
      color: #fff;
      position: relative;
      color: #000;
      padding-top: 40px;

      &::before {
        content: "";
        position: absolute;
        right: calc(100% - 1px);
        top: 0;
        bottom: 0;
        width: 50vw;
        background: #fff;
      }

      h1 {
        position: sticky;
        top: 20px;
      }
    }

    &__right {
      flex: 1 1 0;
      padding: 40px 0 0 50px;
      h2 {
        font-size: 3rem;
      }
      p {
        font-size: 2.4rem;
      }
    }

    &__slogan {
      min-height: 80vh;
      font-size: 10rem;
      line-height: 120%;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
  }
}

.poster {
  overflow: clip;
  padding: 10px 0;

  h1 {
    font-size: 6rem;
  }
  @media (max-width: 1023px) {
    .left-inner {
      padding-bottom: 40px;
      text-align: center;
      .btn {
        display: none;
      }
    }

    &__right {
      text-align: center;

      .btn {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 0;
    &__inner {
      display: flex;
    }

    .left-inner {
      text-align: right;
      position: sticky;
      top: 20px;
    }

    &__left {
      width: 50%;
      padding-right: 50px;
      background: #000;
      color: #fff;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: calc(100% - 1px);
        top: 0;
        bottom: 0;
        width: 50vw;
        background: #000;
      }
      h1 {
        font-size: 8rem;
      }
    }

    &__right {
      flex: 1 1 0;
      position: relative;

      .btn {
        display: none;
      }
    }
  }
}

.footer {
  text-align: center;
  padding: 20vh 0 0;
  font-size: 6rem;
  font-weight: 700;
  padding-bottom: 1px;
  .nav {
    margin-top: 20vh;
    margin-bottom: 50px;
  }
  @media (min-width: 1024px) {
    font-size: 12rem;
  }
}

.btn {
  display: inline-block;
  background: #fff;
  color: #000;
  text-decoration: none;
  padding: 10px 30px;
  font-size: 2rem;
  border-radius: 20px;
  font-weight: 700;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.9;
  }
  @media (max-width: 1023px) {
    background: #000;
    color: #fff;
  }
}

.slider {
  position: relative;
  display: block;
  aspect-ratio: 1 / 1.1;
  overflow: hidden;
  .slide {
    transition: opacity 1s;
    aspect-ratio: 1 / 1.1;
    overflow: hidden;

    &:not(.current) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
    }

    &.current {
      position: relative;
      z-index: 7;
    }
  }
}
